<template>
  <v-navigation-drawer
    v-if="data"
    :key="`${data.id}:${name.en}`"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : ((tab == 2 || tab == 4) ? 'auto': 680)"
    app
    xclipped
    right
    temporary
    class="elevation-8"
  >
    <template #prepend>
      <v-card-title>
        <div>
          <v-icon left>
            fal fa-pills
          </v-icon>
          {{ data?.id ? name.en : 'Add Drug' }}
        </div>
        <v-spacer />
        <v-btn
          icon
          tile
          right
          color="grey"
          @click.stop="$emit('close-drawer')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-tabs
        v-model="tab"
        background-color="primary lighten-1"
        grow
        dark
      >
        <v-tab
          v-for="tabIcon in [
            { icon: 'fal fa-circle-info', disabled: false },
            { icon: 'fal fa-list-check', disabled: !data.id },
            { icon: 'fak fa-light-user-circle-bolt', disabled: !data.id },
            { icon: 'fal fa-diagram-venn', disabled: !data.id },
            { icon: 'fal fa-mortar-pestle', disabled: !data.id },
            { icon: 'fal fa-books', disabled: !data.id },
            { icon: 'fal fa-language', disabled: !data.id }
          ]"
          :key="tabIcon.icon"
          :disabled="tabIcon.disabled"
          class="px-0"
        >
          <v-icon>{{ tabIcon.icon }}</v-icon>
        </v-tab>
      </v-tabs>
    </template>
    <v-form
      ref="drugForm"
      v-model="valid"
      :readonly="!$auth.check({ drugs: 'edit' })"
      @submit.prevent="updateDrug"
    >
      <v-tabs-items v-model="tab">
        <v-container class="spy-0">
          <v-tab-item key="info">
            <v-row class="pt-3 px-2">
              <v-col cols="7">
                <v-text-field
                  v-model="name.en"
                  :label="$t('name') | capitalize"
                  :rules="rules.required"
                  class="required"
                  dense
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model.trim="data.abbreviation"
                  label="Abbr."
                  maxlength="3"
                  dense
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="data.rxcui"
                  label="RXCUI"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="data.brand_names"
                  label="Brand Names"
                  counter="150"
                  maxlength="150"
                  rows="2"
                  auto-grow
                  dense
                />
              </v-col>
              <v-col cols="5">
                <v-select
                  v-model="data.type"
                  label="Type"
                  :items="types"
                  item-text="name"
                  item-value="name"
                  dense
                />
              </v-col>
              <v-col cols="7">
                <v-select
                  v-model="data.class_id"
                  label="Class"
                  :items="classes"
                  item-text="name"
                  item-value="id"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <source-select
                  v-model="data.ineffective_sources"
                  label="Ineffective Sources"
                  prepend-icon="fak fa-fw fa-solid-flask-vial-circle-xmark"
                  dense
                  menu-props="left"
                />
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="data.resistance"
                  :items="resistances"
                  label="Resistance"
                  prepend-icon="fa fa-shield-virus"
                  menu-props="offsetY, closeOnClick"
                  chips
                  deletable-chips
                  small-chips
                  multiple
                  item-value="id"
                  item-text="name.en"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
              >
                <span
                  class="v-label v-label--active mr-2"
                >
                  <v-icon
                    class="mr-1"
                  >
                    fas fa-person-pregnant fa-fw
                  </v-icon>
                  Category
                </span>
                <v-btn-toggle
                  v-model="data.category"
                  dense
                  row
                >
                  <v-btn
                    v-for="option in ['A','B','C','D','X','?']"
                    :key="option"
                    :value="option"
                  >
                    <v-icon x-small>
                      fa-solid fa-{{ option == '?' ? 'question' : option.toLowerCase() }}
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <span
                  class="v-label v-label--active mr-2"
                >
                  <v-icon
                    class="mr-1"
                  >
                    fas fa-person-breastfeeding fa-fw
                  </v-icon>
                  Category
                </span>
                <v-btn-toggle
                  v-model="data.breastfeeding_use"
                  dense
                  row
                >
                  <v-btn
                    v-for="option in [
                      { value: 'acceptable', icon: 'fa-solid fa-thumbs-up', color: 'success darken-1' },
                      { value: 'comment', icon: 'fa-regular fa-comment-lines', color: 'deep-purple' },
                      { value: 'avoid', icon: 'fa-solid fa-ban', color: 'error' },
                    ]"
                    :key="option.value"
                    :value="option.value"
                  >
                    <v-icon
                      x-small
                      :color="data.breastfeeding_use == option.value ? option.color : ''"
                    >
                      {{ option.icon }}
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
                <v-textarea
                  v-model="data.breastfeeding_use_comment"
                  label="Comment"
                  class="ms-10 mt-6"
                  dense
                  lines="1"
                  auto-grow
                  counter
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-checkbox
                  v-model="data.no_pediatric_use"
                  label="Not for pediatric use"
                  prepend-icon="fa-kit fa-solid-child-reaching-circle-xmark"
                  color="error"
                  class="mb-0"
                  inset
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-checkbox
                  v-model="data.sensitivities_option"
                  label="Include in default list of sensitivities for manual lab result entry"
                  prepend-icon="fa-light fa-list-check"
                  class="mb-0"
                  inset
                  dense
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :disabled="!data.id">
            <div class="text-overline">
              ArkScore Factors
            </div>
            <v-list dense>
              <v-list-item
                v-for="(field, key) in fields"
                :key="key"
              >
                <v-list-item-action>
                  <v-switch
                    v-model="data[field.column]"
                    dense
                  />
                </v-list-item-action>
                <v-list-item-content @click="data[field.column] = !data[field.column]">
                  <v-list-item-title v-text="field.text" />
                  <v-list-item-subtitle v-if="field.subtitle">
                    {{ field.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-col>
              <div class="text-overline">
                {{ $tc('drug_info.delivery_methods', 2) }}
              </div>
              <v-row>
                <v-col
                  v-for="method in $delivery_methods"
                  :key="method.value"
                  class="pb-0 mb-0"
                  cols="6"
                >
                  <v-checkbox
                    v-model="data.delivery_methods"
                    :value="method.value"
                    :label="method.text"
                    :prepend-icon="method.icon"
                    dense
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-tab-item>
          <v-tab-item :disabled="!data.id">
            <DrugAdverseReactions
              :drug="data"
            />
          </v-tab-item>
          <v-tab-item :disabled="!data.id">
            <DrugInteractions
              :drug="data"
            />
          </v-tab-item>
          <v-tab-item :disabled="!data.id">
            <v-card
              elevation="0"
              class="mb-4"
              outlined
            >
              <v-data-table
                :items="data.standard_dosages"
                :headers="[
                  {
                    text: 'Dosage',
                    value: 'dosage',
                  },
                  {
                    text: 'Adjustments',
                    value: 'adjustments',
                  },
                  {
                    text: 'Default',
                    value: 'default',
                    align: 'end'
                  }
                ]"
                hide-default-footer
                disable-pagination
                fixed-header
                dense
              >
                <template #top>
                  <v-app-bar
                    dense
                    elevation="0"
                  >
                    <v-row>
                      <v-col class="text-subtitle-1">
                        Standard Dosages
                      </v-col>
                      <v-spacer />
                      <v-col cols="auto">
                        <v-btn
                          color="primary lighten-1"
                          rounded
                          x-small
                          @click="addDosage()"
                        >
                          <v-icon
                            left
                            small
                            class="ml-n1 mr-1"
                          >
                            mdi-plus
                          </v-icon>
                          Dosage
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-app-bar>
                </template>
                <template #item.dosage="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.dosage"
                    save-text="Update"
                    scrollable
                    large
                  >
                    {{ item.dosage }}
                    <span v-if="!item.dosage">
                      <v-icon x-small>
                        fal fa-edit
                      </v-icon>
                    </span>
                    <template #input>
                      <v-text-field
                        v-model.trim="item.dosage"
                        label="Standard Dosage"
                        maxlength="255"
                        class="mt-4"
                      >
                        <template #append-outer>
                          <v-checkbox
                            v-model="item.default"
                            label="Default"
                            class="my-n2"
                            dense
                          />
                        </template>
                      </v-text-field>
                      <v-list dense>
                        <v-subheader class="text-overline">
                          Adjustments
                          <v-spacer />
                          <v-btn
                            x-small
                            text
                            @click="item.adjustments.push({ dosage:null })"
                          >
                            <v-icon
                              class="mr-1"
                              x-small
                            >
                              fal fa-plus-circle
                            </v-icon>
                            Adjustment
                          </v-btn>
                        </v-subheader>
                        <template
                          v-for="adj, index in item.adjustments"
                        >
                          <v-divider
                            :key="index"
                          />
                          <v-list-item
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-content>
                              <v-col cols="2">
                                <v-select
                                  v-model="adj.category"
                                  label="Category"
                                  :items="[
                                    'renal',
                                    'hepatic'
                                  ]"
                                  dense
                                  class="text-capitalize"
                                />
                              </v-col>
                              <v-col cols="2">
                                <v-select
                                  v-model="adj.parameter"
                                  label="Parameter"
                                  :disabled="!adj.category"
                                  :items="[
                                    'Aug Renal',
                                    'CAPD',
                                    'CrCl',
                                    'CRRT',
                                    'CVVH',
                                    'CVVHD',
                                    'CVVHDF',
                                    'eGFR',
                                    'GFR',
                                    'HD',
                                    'Hepatic',
                                    'Hybrid HD',
                                    'PD',
                                    'PIRRT',
                                    'Renal'
                                  ]"
                                  dense
                                />
                              </v-col>
                              <template
                                v-if="['eGFR', 'GFR', 'CrCl'].includes(adj.parameter)"
                              >
                                <v-col cols="1">
                                  <v-select
                                    v-model="adj.modifier"
                                    :items="[
                                      { value: '>', icon: 'greater-than' },
                                      { value: '<', icon: 'less-than' },
                                      { value: '≥', icon: 'greater-than-equal' },
                                      { value: '≤', icon: 'less-than-equal' },
                                      { value: 'range', icon: 'arrows-left-right-to-line' }
                                    ]"
                                    item-text="value"
                                    dense
                                    @change="v => {
                                      if (v == 'range') { adj.value = [0,0]}
                                    }"
                                  >
                                    <template #selection="{ item }">
                                      <v-icon x-small>
                                        far fa-{{ item.icon }}
                                      </v-icon>
                                    </template>
                                    <template #item="{ item }">
                                      <v-icon x-small>
                                        far fa-{{ item.icon }}
                                      </v-icon>
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col
                                  cols="2"
                                  class="mb-n4 mt-4 px-1"
                                >
                                  <v-slider
                                    v-if="adj.modifier != 'range'"
                                    v-model="adj.value"
                                    thumb-label="always"
                                    thumb-size="24"
                                    dense
                                  />
                                  <v-range-slider
                                    v-else
                                    v-model="adj.value"
                                    thumb-label="always"
                                    thumb-size="24"
                                    dense
                                  />
                                </v-col>
                              </template>
                              <template v-if="adj.parameter == 'Hepatic'">
                                <v-col cols="2">
                                  <v-select
                                    v-model.trim="adj.value"
                                    label="Class"
                                    :items="[
                                      'Class A',
                                      'Class B',
                                      'Class C'
                                    ]"
                                    dense
                                  />
                                </v-col>
                              </template>
                              <v-col>
                                <v-text-field
                                  v-model.trim="adj.dosage"
                                  :disabled="adj.no_adjustment"
                                  label="Adjusted Dosage"
                                  dense
                                >
                                  <template #prepend>
                                    <v-checkbox
                                      v-model="adj.no_adjustment"
                                      class="my-n2"
                                      color="grey darken-1"
                                      dense
                                      :on-icon="'fal fa-square-x'"
                                      :off-icon="'fa-square-check'"
                                    />
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                class="my-n5"
                              >
                                <v-text-field
                                  v-model.trim="adj.comment"
                                  label="Comment"
                                  class="text-caption"
                                  dense
                                />
                              </v-col>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                x-small
                                icon
                                @click="item.adjustments.splice(index, 1)"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.adjustments="{ item }">
                  <v-chip
                    v-for="adjustment in item.adjustments.filter((v, i, a) => a.findIndex(x => x.parameter === v.parameter) === i)"
                    :key="adjustment.id"
                    xv-for="parameter in item.adjustments.map(x => x.parameter).filter((v, i, a) => a.findIndex(x => x === v) === i)"
                    x-small
                    class="mr-1"
                  >
                    {{ adjustment.parameter || $options.filters.capitalize(adjustment.category) }}
                  </v-chip>
                </template>
                <template #item.default="{ item }">
                  <v-btn
                    :key="item.id"
                    icon
                    small
                    @click="data.standard_dosages.forEach(x => x.default = 0); item.default = !item.default"
                  >
                    <v-icon
                      v-if="item.default"
                      small
                    >
                      fal fa-check
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
            <v-card
              elevation="0"
              class="mt-4"
              outlined
            >
              <regimen-table
                :items.sync="data.regimens"
                :standard-dosages="data.standard_dosages"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item :disabled="!data.id">
            <v-row class="pa-3">
              <v-col
                cols="12"
              >
                <add-reference
                  :references.sync="data.references"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :disabled="!data.id">
            <v-text-field
              v-for="language in $languages.filter(x => x.value != 'en')"
              :key="language.value"
              v-model="name[language.value]"
              :label="language.text"
              :lang="language.value"
            >
              <template #prepend>
                <country-flag
                  :country="language.flag || language.value"
                  class="mt-n1"
                />
              </template>
              <template
                v-if="language.value !== 'en' && name.en"
                #append
              >
                <v-btn
                  icon
                  @click="translateText(name.en, language.value, v => $set(data.name, language.value, v))"
                >
                  <v-icon small>
                    fa fa-language
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-tab-item>
        </v-container>
      </v-tabs-items>
    </v-form>
    <template
      v-if="$auth.check({ drugs: 'edit' })"
      #append
    >
      <v-divider />
      <v-col>
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateDrug"
        >
          <v-icon
            small
            left
          >
            fal {{ data?.id ? 'fa-check' : 'fa-plus' }}
          </v-icon>
          {{ data?.id ? 'Update' : 'Add' }} Drug
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-btn
          v-if="data.id && $auth.check({ drugs: 'edit' })"
          color="error"
          block
          outlined
          @click="deleteDrug"
        >
          <v-icon
            small
            left
          >
            fal fa-trash
          </v-icon>
          Delete Drug
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>

  export default {
    components: {
      DrugAdverseReactions: () => import("@/components/drug/DrugAdverseReactions.vue"),
      DrugInteractions: () => import("@/components/drug/DrugInteractions.vue"),
      SourceSelect: () => import("@/components/source/SourceSelect.vue"),
      AddReference: () => import("@/components/reference/AddReference.vue"),
      RegimenTable: () => import("@/components/drug/RegimenTable.vue"),
      CountryFlag: () => import('vue-country-flag'),
    },
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        tab: 'info',
        valid: false,
        loading: false,
        lang: 'EN',
        types: [
          { name: 'Antibacterial', icon: 'fal fa-bacterium' },
          { name: 'Antifungal', icon: 'fal fa-disease' },
          { name: 'Antimalarial', icon: 'fal fa-virus' },
          { name: 'Antiparasitic', icon: 'fal fa-bug' },
          { name: 'Antiviral', icon: 'fal fa-virus' },
          { name: 'Antiparasitic', icon: 'fal fa-bug' },
          { name: 'Antimalarial', icon: 'fal fa-virus' },
        ],
        fields: [
          {
            text: 'Broad-spectrum ABX',
            subtitle: 'AKA reserved for resistant bacteria AKA used for first line ABX',
            column: 'broad_spectrum',
          },
          {
            text: 'Risk for C.diff',
            subtitle: 'Clinda, quinolones, cephalosporins, aztreonam and carbapenems',
            column: 'c-diff_risk',
          },
          {
            text: 'No oral version available',
            column: 'no_oral_option',
          },
          {
            text: 'Risk of single system organ failure',
            column: '1_system_organ_failure',
          },
          {
            text: 'Risk of 2 or more system organ failure',
            column: '2+_system_organ_failure',
          },
          {
            text: 'Risk of drug interactions',
            column: 'risk_of_drug_interactions',
          },
          {
            text: 'FDA warnings/black box',
            column: 'fda_warnings',
          },
          {
            text: 'Lab monitoring required',
            column: 'lab_monitoring',
          },
          {
            text: 'Second line option',
            column: 'second_line',
          },
          {
            text: 'Requires renal dosing adjustment',
            column: 'renal_dosing',
          },
          {
            text: 'Requires hepatic dosing adjustment',
            column: 'hepatic_dosing',
          },
        ],
        resistances: [],
        classes: [],
        side_effects: [],
        rules: {
          required: [
            v => !!v || this.$t('required'),
          ],
        },
        // resistance: this.data.resistance ? this.data.resistance.map(x => x.id) : [],
      }
    },
    computed: {
      name: {
        get () {
          return this.data.id ? this.data.name : { en: null }
        },
        set (v) {
          this.$set(this.data, 'name', v)
        },
      },
    },
    created () {
      this.axios.get('admin/resistances', { params: { count: 1000 } }).then((response) => {
        this.resistances = response.data.data
      })
      this.axios.get('admin/drugs/classes').then((response) => {
        this.classes = response.data
      })
      // this.axios.get('admin/drugs/side_effects').then((response) => {
      //   this.side_effects = response.data
      // })
    },
    methods: {
      addDosage (age_bracket) {
        this.data.standard_dosages.push({
          dosage: null,
          adjustments: [],
          age_bracket: age_bracket,
        })
      },
      updateDrug () {
        this.$refs.drugForm.validate()
        if (this.valid) {
          this.loading = true
          this.axios.request({
            method: this.data.id ? 'patch' : 'post',
            url: this.data.id ? 'admin/drugs/' + this.data.id : 'admin/drugs',
            data: this.data,
          })
            .then(() => {
              this.$root.$emit('fetch-drugs')
              this.$emit('close-drawer')
              this.$toast.success('Drug updated')
            })
            .catch(error => {
              this.$toast.error(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      deleteDrug () {
        this.$dialog.warning({
          text: 'Do you really want to delete this drug?',
          title: 'Delete Drug',
          icon: 'fas fa-exclamation-triangle mr-2',
          actions: {
            true: {
              text: 'Yes', color: 'red',
            },
            false: 'No',
          },
        })
          .then((res) => {
            if (res) {
              this.axios.delete(`admin/drugs/${this.data.id}`)
                .then(() => {
                  this.$root.$emit('fetch-drugs')
                  this.$toast.success('Drug deleted')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
      close () {
        this.$destroy();
        if (this.$el) {
          this.$el.parentNode.removeChild(this.$el);
        }
      },
    },
  }
</script>
<style>
  .required .v-label::after {
    content: " *";
    color: red;
  }
</style>
